<template>
  <EventBase
    :max-height="null"
    :height="null"
    :min-height="null"
    class="black-background"
  >
    <div class="parent">
      <v-img
        :src="bannerDesktop"
        :lazy-src="bannerDesktopLazy"
        max-width="1400px"
        width="100vw"
        contain
        alt="Resurrection Rhythms - Sunday mornings starting March 9th"
        v-if="!['xs'].includes($vuetify.breakpoint.name)"
      >
      </v-img>
      <v-img
        :src="bannerMobile"
        :lazy-src="bannerMobileLazy"
        width="100vw"
        max-height="90vh"
        contain
        alt="Resurrection Rhythms - Sunday mornings starting March 9th"
        v-else
      >
      </v-img>
    </div>
  </EventBase>
</template>
<script>
import EventBase from "../EventBase.vue";
import bannerDesktop from "./resurrection-rhythms.jpg";
import bannerDesktopLazy from "./resurrection-rhythms.jpg";
import bannerMobile from "./resurrection-rhythms.jpg";
import bannerMobileLazy from "./resurrection-rhythms.jpg";
export default {
  name: "EasterChoir",
  components: {
    EventBase,
  },
  data: () => ({
    bannerDesktop,
    bannerDesktopLazy,
    bannerMobile,
    bannerMobileLazy,
  }),
};
</script>
<style lang="scss" scoped>
.parent {
  position: relative;
}

.button-container {
  position: absolute;
  bottom: 32px;
  z-index: 1;
  width: 100%;
  text-align: center;
}
</style>
