<template>
  <div>
    <PageHeadingFullScreen
      :src="headerImage"
      :srcMobile="headerImageMobile"
      :srcLazy="headerImageLazy"
      title="Events"
      filter="brightness(30%) contrast(80%)"
    ></PageHeadingFullScreen>
    <NewsletterSection></NewsletterSection>
    <!-- <ToyGiveAway></ToyGiveAway> -->
    <!-- <BackToSchool></BackToSchool> -->
    <!-- <VBS></VBS> -->
    <!-- <PineValley></PineValley> -->
    <!-- <EasternCamp></EasternCamp> -->
    <EasterChoir></EasterChoir>
    <!-- <TwentyFifth></TwentyFifth> -->
    <!-- <ChristmasChoir></ChristmasChoir> -->
  </div>
</template>
<script>
import PageHeadingFullScreen from "../components/PageHeadingFullScreen.vue";
import NewsletterSection from "../components/NewsletterSection.vue";

import headerImage from "./_MG_4649-greeninger-cropped-compressed-large.webp";
import headerImageMobile from "./_MG_4649-greeninger-cropped-compressed-mobile.webp";
import headerImageLazy from "./_MG_4649-greeninger-cropped-compressed-lazy.webp";

// import ChristmasChoir from "./events/ChristmasChoir/ChristmasChoir.vue";
// import TwentyFifth from "./events/TwentyFifth.vue";

// import PineValley from "./events/PineValley.vue";
// import VBS from "./events/VBS.vue";
// import BackToSchool from "./events/BackToSchool/BackToSchool.vue";
// import EasternCamp from "./events/EasternCamp.vue";
// import ToyGiveAway from "./events/ToyGiveAway/ToyGiveAway.vue";

import EasterChoir from "./events/EasterChoir/EasterChoir.vue";

export default {
  name: "Events",
  components: {
    PageHeadingFullScreen,
    NewsletterSection,
    // ChristmasChoir,
    // PineValley,
    // VBS,
    // EasternCamp,
    // BackToSchool,
    // ToyGiveAway,
    EasterChoir,
    // TwentyFifth,
  },
  data: () => ({ headerImage, headerImageMobile, headerImageLazy }),
};
</script>
<style lang="scss" scoped></style>
